import PropTypes from 'prop-types'
import React, {useState} from "react"
import {Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,} from "reactstrap"
import ScrollspyNav from "./scrollSpy"

//Import Images
import {Link} from "react-router-dom";

const navItems = [
    {id: 1, idnm: "home", navheading: "Home"},
    {id: 2, idnm: "about", navheading: "About"},
    {id: 4, idnm: "team", navheading: "Management"},
]

const Navbar_Page = props => {
    const [isOpenMenu, setisOpenMenu] = useState(false)

    //Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
    let TargetId = navItems.map(item => {
        return item.idnm
    })

    return (
        <React.Fragment>
            <Navbar
                expand="lg"
                fixed="top"
                className={"navigation sticky " + props.navClass}
            >
                <Container>
                    <NavbarBrand className="navbar-logo" href="/">
                        {props.imglight !== true ? (
                           <h4 className={"text-dark"}>UPR Africa</h4>
                        ) : (
                            <h4 className={"text-white"}>UPR Africa</h4>
                        )}
                    </NavbarBrand>

                    <NavbarToggler
                        className="p-0"
                        onClick={() => {
                            setisOpenMenu()
                        }}
                    >
                        <i className="fa fa-fw fa-bars"/>
                    </NavbarToggler>

                    <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
                            <Nav className="ms-auto navbar-nav" id="topnav-menu">
                                {navItems.map((item, key) => (
                                    <NavItem
                                        key={key}
                                        className={item.navheading === "Home" ? "active" : ""}
                                    >
                                        <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        <div className="ms-lg-2">
                            <a href="#footer" className="btn btn-outline-success w-xs">
                                Contact Us
                            </a>
                        </div>
                    </Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}

Navbar_Page.propTypes = {
    imglight: PropTypes.any,
    navClass: PropTypes.string
}

export default Navbar_Page
