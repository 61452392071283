import React, {useState} from "react"
import {Col, Container, Modal, Row} from "reactstrap"

//Import Images
import avatar2 from "../../assets/images/users/avatar-2.png"
import {Link} from "react-router-dom";
import TeamDetails from "./team-details";

const OurTeam = () => {
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [currentTeam, setCurrentTeam] = useState({})

    const [openModal, setOpenModal] = useState(false)

    const teams = [
        {
            name: "Mr. Gilbert Onyango",
            position: "Executive Director",
            des: "Mr. Gilbert Onyango is the Executive Director at UPR Africa. He is the Founder and a\n" +
                "member of the Board of Trustees and Board of Directors of EACHRights. He started\n" +
                "his NGO career in 2002 when he joined The CRADLE-The Children’s Foundation as\n" +
                "an intern and over the years rose to the position of Deputy Director. Between June\n" +
                "and December 2010, he worked as Regional Deputy Director for the Canadian Bar\n" +
                "Association’s Strengthening Access to Justice in Eastern Africa (SAJEA) Programme in\n" +
                "Dar es Salaam, Tanzania. He served as Regional Director of EACHRights from\n" +
                "December 2010 to May 2015. Between 2015 and 2019, he was the Regional Director\n" +
                "for Africa at UPR Info Africa. He is also a member of the Board of Directors at ICS-SP\n" +
                "Africa.\n" +
                "Mr. Onyango graduated in 1999 with an LL.B Degree from Dr. B.A.M. University,\n" +
                "Aurangabad, India and was admitted to the Kenyan Bar in 2002. He is an advocate\n" +
                "of the High Court of Kenya, Commissioner for Oaths and Notary Public. In 2008,\n" +
                "Gilbert graduated from the United States International University, Africa (USIU-A)\n" +
                "with a Master’s of Science (M.Sc.) Degree in Management and Organisational\n" +
                "Development.\n" +
                "In 2012, Gilbert graduated with a Masters of Arts (M.A.) Degree in Development\n" +
                "Studies with major in Human Rights, Development and Social Justice from the\n" +
                "International Institute of Social Studies (ISS) of Erasmus University, Rotterdam. He\n" +
                "\n" +
                "has attended numerous professional development courses both locally and\n" +
                "internationally in child rights, human rights, law, development, strategic planning\n" +
                "and management, human resource management, project evaluation and grant\n" +
                "making."
        },
        {
            name: "Mr. Fredrick Kinama",
            position: "Finance & Operations Manager",
            des: "Mr. Fredrick Kinama is the Finance and Operations Manager at UPR Africa. Prior to\n" +
                "this, he was the Finance &amp; Administration Officer at UPR Info Africa between May 2016\n" +
                "and 2019. Between 2012 and 2019, Mr. Kinama worked as the Finance Advisor for the\n" +
                "at Tearfund - UK WASH projects in Liberia &amp; Sierra Leone and was based in Monrovia,\n" +
                "Liberia. Between 2013 and 2014, he worked as a Project Accountant for the Aga Khan\n" +
                "Foundation’s Regional Health Agency in Lyon where he was supporting education\n" +
                "programs in Tanzania, Kenya and Zanzibar. He worked as Finance Assistant before\n" +
                "joining USAID in Juba, South Sudan as the Finance Manager and Finance Consultant\n" +
                "to the South Sudan Government Commissions where he was instrumental in setting up\n" +
                "\n" +
                "systems and developing policies between 2004 – 2008. He is a Certified Public\n" +
                "Accountant, with a Bachelor of Business Administration and a Diploma in Business\n" +
                "Management."
        }
    ]


    return (
        <React.Fragment>
            <section className="section" id="team">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Management</h4>
                                <div className="small-title">The Key and Management staff are highlighted below</div>
                            </div>
                        </Col>
                    </Row>

                    <Col lg="12">
                        <div className="hori-timeline">
                            <div
                                className="owl-carousel owl-theme  navs-carousel events"
                                id="timeline-carousel"
                            >
                                {step1 ? (
                                    <>
                                        <Row>
                                            {teams.map((a) => <Col md={6}>
                                                <div className="item">
                                                    <div className="card text-center team-box">
                                                        <div className="card-body">
                                                            <div>
                                                                <img src={avatar2} alt="" className="rounded"
                                                                     style={{width: 100}}/>
                                                            </div>

                                                            <div className="mt-3">
                                                                <h5>{a.name}</h5>
                                                                <p className="text-muted mb-0">{a.position}</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="card-footer bg-transparent border-top">
                                                            <div className="d-flex mb-0 team-social-links">
                                                                <div className="flex-fill">
                                                                    <button className="btn btn-outline-dark"
                                                                            onClick={e => {
                                                                                setCurrentTeam(a)
                                                                                setOpenModal(true)
                                                                            }}>
                                                                        Details
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>)}

                                        </Row>
                                    </>
                                ) : null}

                                <TeamDetails currentTeam={currentTeam} open={openModal} setOpen={setOpenModal}/>


                            </div>
                        </div>
                    </Col>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default OurTeam
