import React, {useState} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap"


const AboutUs = () => {

    return (
        <React.Fragment>
            <section className="section pt-6 bg-white" id="about">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Background</h4>
                                <div className="small-title">Learn more about UPR Africa</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="12">
                            <div className="text-muted">
                                <p>
                                    In 2006 the United Nations General Assembly near unanimously adopted Resolution
                                    60/251, to establish
                                    a Human Rights Council (replacing the Human Rights Commission) with the
                                    responsibility for promoting
                                    universal respect for the protection of all human rights and fundamental freedoms
                                    for all. One of the
                                    key mandates of the Council is to undertake a Universal Periodic Review (UPR), on
                                    the fulfillment of
                                    each States’ human rights obligations and commitments.
                                </p>
                                <p className="mb-4">
                                    The UPR is therefore a unique
                                    Human Rights
                                    mechanism where each of the 194 UN Members States are peer-reviewed and examined on
                                    their entire
                                    human rights record every five years regardless of its size or political influence,
                                    under the same rules
                                    and supervision. States are required to respond not only to all recommendations made
                                    by its peers, but
                                    also to provide data on the implementation of recommendations it has previously
                                    Accepted, including
                                    voluntary commitments. It remains a co-operative process that requires the full
                                    participation of the
                                    State under Review.
                                </p>

                                <p className="mb-4">
                                    It complements the work of all UN treaty bodies. The UPR is a
                                    very important
                                    process for advancing the realization of human rights based on the recommendations
                                    that are received
                                    by the State.
                                </p>


                            </div>
                        </Col>

                    </Row>

                    <hr className="mt-5 mb-5"/>

                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Kenya’s UPR process</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="12">
                            <div className="text-muted">
                                <p>
                                    Ahead of Kenya’s 3 rd Cycle UPR, members of Kenya’s Stakeholders Coalition on the
                                    UPR came together
                                    to draft over 25 reports that were submitted to the UN Human Rights Council. Kenya’s
                                    human rights
                                    record was reviewed for the third time by the UN Human Rights Council on 23 rd
                                    January, 2020. 3 days
                                    after the review, the Government received an Outcome Document. At the said review,
                                    Kenya received a
                                    total 319 recommendations (compared to 253 in the second cycle in 2015). Of these,
                                    the State
                                    outrightly Accepted 261 recommendations. It Deferred 5 recommendations (to allow for
                                    consultation
                                    with the relevant MDAs in Nairobi); and out rightly Noted 53 recommendations. The
                                    Outcome
                                    Document of the review, the Working Group report, was to be formally adopted by the
                                    UN Human
                                    Rights Council in October, 2020.
                                </p>
                            </div>
                        </Col>

                    </Row>

                    <hr className="mt-5 mb-5"/>

                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Kenya’s Stakeholders Coalition on the UPR</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="12">
                            <div className="text-muted">
                                <p>
                                    The Kenya Stakeholders’ Coalition on the UPR has a membership of over 100 NGOs and
                                    is led by a
                                    Steering Committee with technical support from the Kenya National Commission on
                                    Human Rights
                                    (KNCHR); and the UN Office of the High Commissioner on Human Rights (OHCHR). During
                                    the last Cycle,
                                    the Coalition developed a 2 nd Cycle UPR Mid-Term Report that was launched in
                                    January, 2019.
                                    Thereafter, the Coalition supported the development of over 25 individual cluster
                                    reports that were
                                    submitted to the UN Human Rights Council in July, 2019 ahead of the 3 rd Cycle
                                    Review. The members of
                                    the coalition contribute funds to undertake activities.
                                </p>
                            </div>
                        </Col>

                    </Row>

                </Container>
            </section>
        </React.Fragment>
    )
}

export default AboutUs
