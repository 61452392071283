import React, {useState} from "react"
import {Col, Container, Modal, Row} from "reactstrap"

//Import Images
import picOne from "../../assets/images/gallery/1.jpeg"
import picTwo from "../../assets/images/gallery/2.jpeg"
import picThree from "../../assets/images/gallery/3.jpeg"
import TeamDetails from "./team-details";

const Gallery = () => {
    const teams = [
        {
            image: picOne,
        },
        {
            image: picTwo,
        },
        {
            image: picThree,
        }
    ]


    return (
        <React.Fragment>
            <section className="section bg-white" id="team">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Gallery</h4>
                                <div className="small-title">Explore our gallery</div>
                            </div>
                        </Col>
                    </Row>

                    <Col lg="12">
                        <Row>
                            {teams.map((a) => <Col md={6}>
                                <div className="item">
                                    <div className="card text-center team-box">
                                        <div className="card-body">
                                            <div>
                                                <img src={a.image} alt=""
                                                     style={{width: '100%'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>)}

                        </Row>
                    </Col>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Gallery
