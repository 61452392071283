import React from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button, Media,
} from "reactstrap"
import {Link} from "react-router-dom"

const Section = () => {
    return (
        <React.Fragment>
            <section className="section hero-section bg-ico-hero" id="home">
                <div className="bg-overlay bg-primary"/>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="5">
                            <div className="text-white-50">
                                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                                    UPR Africa
                                </h1>
                                <p className="font-size-14 text-white">
                                    The UPR is a very important
                                    process for advancing the realization of human rights based on the recommendations
                                    that are received
                                    by the State. It complements the work of all UN treaty bodies.
                                </p>

                                <div className="button-items mt-4">
                                    <a href={"#about"}  className="btn btn-success me-1">
                                        Learn more
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5" md="8" sm="10" className="ms-lg-auto">
                            <Card className="overflow-hidden mb-0 mt-5 mt-lg-0">

                                <CardBody>

                                    <Media className="faq-box mb-4">
                                        <div className="faq-icon me-3">
                                            <i className="fa fa-eye font-size-20 text-success"/>
                                        </div>
                                        <Media body>
                                            <h5 className="font-size-15">
                                                Background
                                            </h5>
                                            <p className="text-muted">
                                                In 2006 the United Nations General Assembly near unanimously adopted
                                                Resolution 60/251, to establish
                                                a Human Rights Council (replacing the Human Rights Commission) with the
                                                responsibility for promoting
                                                universal respect for the protection of all human rights and fundamental
                                                freedoms for all. One of the
                                                key mandates of the Council is to undertake a Universal Periodic Review
                                                (UPR), on the fulfillment of
                                                each States’ human rights obligations and commitments. The UPR is
                                                therefore a unique Human Rights
                                                mechanism where each of the 194 UN Members States are peer-reviewed and
                                                examined on their entire
                                                human rights record every five years regardless of its size or political
                                                influence, under the same rules
                                                and supervision...<a href={"#about"} className="ms-1">
                                                Learn more
                                            </a>
                                            </p>
                                        </Media>
                                    </Media>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Section
