import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';

//Import Components
import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import AboutUs from "./AboutUs/about-us"
import OurTeam from "./Team/our-team"
import Footer from "./Footer/footer"
import Gallery from "./Team/gallery";

const CryptoIcoLanding = () => {
    const [imglight, setimglight] = useState(true)
    const [navClass, setnavClass] = useState("")

    // Use ComponentDidMount
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true)
    })

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > 80) {
            setimglight(false)
            setnavClass("nav-sticky")
        } else {
            setimglight(true)
            setnavClass("")
        }
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>UPR Africa</title>
            </MetaTags>

            <Navbar navClass={navClass} imglight={imglight}/>

            <Section/>

            <AboutUs/>

            <OurTeam/>

            <Gallery/>

            <Footer/>
        </React.Fragment>
    )
}

export default CryptoIcoLanding
